import { Typography } from '@/core/ui';
import type { IPlayer } from '@/types';

interface IProps {
  data: IPlayer[];
}

export const PlayerProfile = ({ data }: IProps) => {
  if (data.length > 1) {
    return (
      <div className='flex flex-col space-y-[25px] w-48 justify-start break-words items-center'>
        {data.map((d) => (
          <div key={d.id} className='flex flex-row w-full space-x-4 justify-start items-center overflow-hidden'>
            {d.player_image?.path ? (
              <img
                src={d.player_image.path}
                alt={d.player_image.caption || ''}
                className='bg-chalk rounded-full w-[45px] h-[45px] object-cover overflow-hidden'
              />
            ) : (
              <img
                src='/default.png'
                alt='default'
                className='bg-chalk rounded-full w-[45px] h-[45px] object-cover overflow-hidden'
              />
            )}

            <Typography className='line-clamp-2 text-xl pt-1 text-chalk tracking-[1.4px] leading-[22px] uppercase max-w-36'>
              {d.name}
            </Typography>
          </div>
        ))}
      </div>
    );
  }

  const player = data[0];

  console.log('player', player);

  return (
    <div className='flex flex-col space-y-4 items-center'>
      {player?.player_image?.path ? (
        <img
          src={player.player_image.path}
          alt={player.player_image.caption || ''}
          className='bg-chalk rounded-full mr-4 w-[141px] h-[141px] object-cover overflow-hidden'
        />
      ) : (
        <img
          src='/default.png'
          alt={player?.player_image?.caption || ''}
          className='bg-chalk rounded-full mr-4 w-[141px] h-[141px] object-cover overflow-hidden'
        />
      )}
      <Typography className='text-center text-xl line-clamp-2 text-chalk leading-5 tracking-[1.4px] uppercase font-semibold max-w-36'>
        {player?.name}
      </Typography>
    </div>
  );
};
