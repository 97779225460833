import type {
  ICompetition,
  ICompetitionGallery,
  ICompetitionHighlight,
  ICompetitionInfo,
  ICompetitionMembers,
} from '@/types';
import { nationalTeam } from './national-team';
import { players, techTeamMembers } from './players';
import { president } from './president';

export const competitionInfo: ICompetitionInfo = {
  id: 1,
  name: 'LIGA DAS NAÇÕES',
  year: 2009,
  trophy_image: {
    path: 'https://admin.fpf.gemadigital.com/uploads/trophy.png',
    mime_type: 'image/png',
    caption: 'Trophy',
  },
  competition_text:
    '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniamempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</p>',
  competition_logo: {
    path: 'https://admin.fpf.gemadigital.com/uploads/logo.png',
    mime_type: 'image/png',
    caption: 'logo',
  },
  competition_image: {
    path: 'https://admin.fpf.gemadigital.com/uploads/FotoFull.png',
    mime_type: 'image/png',
    caption: 'competition full image',
  },
  complementary_text:
    '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolo</p>',
  national_team: nationalTeam,
  trophy_video: {
    path: 'https://admin.fpf.gemadigital.com/uploads/FotoFull.png',
    mime_type: 'video/mp4',
    caption: 'trophy video',
  },
};

export const competitionStats: any = {
  statistic_text:
    '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempo</p>',
  statistic_games: {
    wins: 2,
    draws: 0,
    defeats: 0,
  },
  statistic_image: {
    path: 'https://admin.fpf.gemadigital.com/uploads/FotoFull.png',
    mime_type: 'image/png',
    caption: 'competition full image',
  },
  statistic_goals: {
    scored: 4,
    conceded: 1,
  },
  statistic_goal_scorers: [
    {
      ...players[0],
      goals: 2,
    },
    // {
    //   ...players[1],
    //   goals: 2,
    // },
    // {
    //   ...players[2],
    //   goals: 2,
    // },
  ],
  curiosity_image: {
    path: 'https://admin.fpf.gemadigital.com/uploads/FotoFull.png',
    mime_type: 'image/png',
    caption: 'competition full image',
  },
  curiosity_text: 'test',
};

export const competitionMembers: ICompetitionMembers = {
  squad: players,
  technical_team: techTeamMembers,
  president_info: {
    president,
    president_text:
      '<p>Fernando Soares Gomes da Silva nasceu a 21 de fevereiro de 1952, na freguesia de Cedofeita, no Porto. Licenciado em Economia, em 1976, iniciou a sua atividade profissional na NCR, tendo sido quadro no Grupo Amorim e na Sonae.</p>',
  },
};

export const competitionGallery: ICompetitionGallery = {
  gallery: [
    {
      caption: '',
      path: '/gallery/game1.png',
      mime_type: 'image/jpeg',
    },
    {
      caption: 'Et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco',
      path: '/gallery/game2.png',
      mime_type: 'image/jpeg',
    },
    {
      caption: '',
      path: '/gallery/game4.png',
      mime_type: 'image/jpeg',
    },
    {
      caption: 'Punakaiki Coast',
      path: 'https://images.unsplash.com/photo-1707007694363-b8afb46ed639?q=80&w=2971&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      mime_type: 'image/jpeg',
    },
    {
      caption: 'Golden Hour',
      path: 'https://images.unsplash.com/photo-1522747776116-64ee03be1dad?q=80&w=2970&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      mime_type: 'image/jpeg',
    },
  ],
};

export const competitionHighlight: ICompetitionHighlight = {
  highlight: {
    media_1: {
      caption: '',
      path: '/gallery/game1.png',
      mime_type: 'image/jpeg',
    },
    media_2: {
      caption: 'Et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco',
      path: '/gallery/game2.png',
      mime_type: 'image/jpeg',
    },
    media_3: {
      caption: '',
      path: '/gallery/game4.png',
      mime_type: 'image/jpeg',
    },
  },
};

export const competitions: ICompetition[] = [
  {
    ...competitionInfo,
    ...competitionStats,
    ...competitionMembers,
    ...competitionGallery,
    ...competitionHighlight,
    id: 1,
  },
  {
    ...competitionInfo,
    ...competitionStats,
    ...competitionMembers,
    ...competitionGallery,
    ...competitionHighlight,
    year: 2016,
    id: 2,
  },
  {
    ...competitionInfo,
    ...competitionStats,
    ...competitionMembers,
    ...competitionGallery,
    ...competitionHighlight,
    year: 2012,
    id: 3,
  },
  {
    ...competitionInfo,
    ...competitionStats,
    ...competitionMembers,
    ...competitionGallery,
    ...competitionHighlight,
    year: 2000,
    id: 4,
  },
];
