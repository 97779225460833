import { useEffect, useRef, useState } from 'react';
import { Dialog, DialogClose, DialogContent, DialogTitle, HtmlMarkup } from '@/core/ui';
import { Icon } from '@/core/icon';
import type { IPicture } from '@/types';

export const ScrollingDialog = ({
  open,
  onClose,
  curiosityText,
  curiosityImage,
  title,
}: {
  open: boolean;
  onClose: () => void;
  curiosityText: string;
  curiosityImage: IPicture;
  title: string;
}) => {
  const scrollableRef = useRef<HTMLDivElement | null>(null);
  const [scrollSections, setScrollSections] = useState<number[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [showGradient, setShowGradient] = useState(true);
  const [gradientPosition, setGradientPosition] = useState(0);
  let scrollTimeout: NodeJS.Timeout;

  const calculateScrollSections = () => {
    const container = scrollableRef.current;
    if (container) {
      const clientHeight = container.clientHeight;
      const scrollHeight = container.scrollHeight;

      const sections = Math.ceil(scrollHeight / clientHeight);
      if (sections > 1) {
        setScrollSections([...Array(sections).keys()]);
      } else {
        setScrollSections([]);
      }
    }
  };

  const handleDotClick = (index: number) => {
    if (scrollableRef.current) {
      const clientHeight = scrollableRef.current.clientHeight;
      scrollableRef.current.scrollTo({
        top: index * clientHeight,
        behavior: 'smooth',
      });
      setSelectedIndex(index);
    }
  };

  const handleScroll = () => {
    if (scrollableRef.current) {
      const { scrollTop, clientHeight, scrollHeight } = scrollableRef.current;
  
      const totalSections = Math.ceil(scrollHeight / clientHeight);
  
      if (scrollHeight - clientHeight === 0) return;
  
      const scrollPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;
  
      const sectionHeightPercentage = 100 / totalSections;
      const index = Math.min(totalSections - 1, Math.floor((scrollPercentage + sectionHeightPercentage / 2) / sectionHeightPercentage));
  
      setSelectedIndex(index);
      setShowGradient(false);
      setGradientPosition(scrollTop);
  
      clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(() => {
        setShowGradient(scrollTop + clientHeight < scrollHeight);
      }, 150);
    }
  };
  

  useEffect(() => {
    if (open) {
      const timer = setTimeout(() => {
        calculateScrollSections();
      }, 50);
  
      return () => clearTimeout(timer);
    }
  }, [open, curiosityText]);
  

  const resetScrollState = () => {
    const container = scrollableRef.current;
    if (container) {
      container.scrollTop = 0;
    }
    setSelectedIndex(0);
  };

  useEffect(() => {
    if (open) {
      resetScrollState();
      calculateScrollSections();
    }
  }, [open, curiosityText]);

  useEffect(() => {
    const container = scrollableRef.current;
    if (container && open) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [scrollableRef.current, open]);

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent
        closeJsx={
          <DialogClose onClick={onClose} className="absolute right-8 top-8 rounded-sm bg-transparent">
            <div className="bg-red-600 rounded-full">
              <Icon name="X_ICON" className="p-2 w-16 h-16" color="#FFF" />
              <span className="sr-only">Close</span>
            </div>
          </DialogClose>
        }
        className="bg-transparent flex justify-center items-center overflow-hidden shadow-none h-[80%] max-w-[90%] sm:rounded-2xl"
        aria-labelledby="dialog-title"
        aria-describedby="scrolling-dialog-description"
      >
        <div className="bg-[#E6F5F1] bg-trivia flex flex-row justify-between items-stretch rounded-2xl w-full h-full relative">
          <div className="relative flex flex-col flex-1 mx-28 py-28 w-[600px] space-y-6 pb-28">
            <DialogTitle className="ml-4 font-united-sans text-[45px] font-bold uppercase leading-[42px] line-[27.6px] tracking-[1.2px]">
              {title}
            </DialogTitle>
            <div
              className="ml-4 overflow-y-auto mx-auto w-[600px] h-[649px] relative rounded-sm"
              ref={scrollableRef}
            >
               
              <HtmlMarkup className="relative text-[24px] w-[566px] font-medium leading-[30px] tracking-[1.2px] flex flex-col space-y-4">
                {curiosityText}
              </HtmlMarkup>
              <div
                className={`absolute left-0 w-full h-16 bg-gradient-to-t from-white to-transparent pointer-events-none transition-opacity duration-300 ${
                  showGradient ? 'opacity-100' : 'opacity-0'
                }`}
                style={{ bottom: -gradientPosition }}
              />
            </div>
            <div className="absolute -right-4 top-0 bottom-0 flex flex-col items-center justify-center space-y-2 z-20">
              {scrollSections.map((_, index) => (
                <button
                  key={index}
                  type="button"
                  onClick={() => handleDotClick(index)}
                  className={`w-4 h-4 rounded-full ${
                    index === selectedIndex ? 'bg-[#C61D23]' : 'bg-gray-300'
                  }`}
                />
              ))}
            </div>
          </div>
          <div className="flex w-[903px] items-center">
            <img
              src={curiosityImage.path}
              alt={curiosityImage.caption || ''}
              className="w-full h-full object-cover"
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
