import { Stack } from '@/core/blocks';
import { HtmlMarkup, Typography } from '@/core/ui';
import type { ICompetitionInfo } from '@/types';
import { useTranslation } from 'react-i18next';

export const TrophyData = ({ competition }: { competition: ICompetitionInfo }) => {
  console.log("competition:", competition); 
  const { t } = useTranslation('translation');
  return (
    <div className='h-screen w-full bg-content1 grid grid-cols-3 px-24 relative'>
      <div className='absolute left-0 top-0'>
        <Stack upperClass='w-[52px] h-[294px]' lowerClass='w-[52px] h-[87px]' />
      </div>
      <div className='absolute bottom-0 right-0'>
        <img
          className='w-[576px] h-[408px] object-contain'
          src={competition.competition_image.path}
          alt={competition.competition_image.caption || ''}
        />
      </div>
      <div className='col-span-1 flex flex-col mt-[71px] space-y-8 ml-20'>
        <Typography className='text-chalk text-3xl font-semibold leading-[28.5px] tracking-[1.5px]'>
          {t('competition.trophy')}
        </Typography>
        <div className='pt-10'>
          <img
            src={competition.competition_logo.path}
            alt={competition.competition_logo.caption || ''}
            className='w-[125px] h-[125px] object-contain '
          />
        </div>
        <Typography className='uppercase text-chalk text-[65px] tracking-[3.25px] leading-[61.75px] font-semibold'>
          {competition.name}
        </Typography>
        <HtmlMarkup className='max-w-[480px] text-chalk text-2xl leading-[29.9px] tracking-[1.3px]'>
          {competition.competition_text}
        </HtmlMarkup>
        <div className='flex flex-col space-y-[14px] pt-12'>
          <Typography className='text-olive text-[26px] leading-[24.7px] tracking-[1.3px]'>
            {competition.national_team?.modality?.name} - {competition.national_team?.gender}
          </Typography>
          <Typography className='text-chalk text-[26px] leading-[24.7px] tracking-[1.3px]'>
            {competition.national_team.name}
          </Typography>
          <Typography className='text-chalk text-[26px] leading-[24.7px] tracking-[1.3px]'>
            {competition.year}
          </Typography>
        </div>
      </div>
      <div className='col-span-1 items-center flex justify-end'>
        {competition.trophy_video?.path ? (
          <video
            className='w-[478px] h-[865px] object-contain'
            controls
            autoPlay
            loop
            muted
            src={competition.trophy_video.path}
          >
            Your browser does not support the video tag.
          </video>
        ) : (
          <img
            className='w-[478px] h-[865px] object-contain'
            src={competition.trophy_image.path}
            alt={competition.trophy_image.caption || ''}
          />
        )}
      </div>
      <div className='col-span-1 flex h-[60%] justify-end items-start mt-48'>
        <div className='max-w-[480px] overflow-y-auto no-scrollbar max-h-[26rem]'>
          <HtmlMarkup className='text-chalk text-2xl leading-[29.9px] tracking-[1.3px]'>
            {competition.complementary_text}
          </HtmlMarkup>
        </div>
      </div>
    </div>
  );
};
