import { Stack } from '@/core/blocks';
import { Button, HtmlMarkup, Typography } from '@/core/ui';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BestScore } from './best-score';
import { GameData } from './game-data';
import { GoalData } from './goal-data';
import { ScrollingDialog } from '@/components/scrollingdialog/scrollingdialog';

export const CompetitionStats = ({
  data,
}: {
  data: any;
}) => {
  const { t } = useTranslation('translation');
  const [open, setOpen] = useState(false);

  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  const isOverflowing = data.competition.statistic_text.length > 300;

  return (
    <div className="w-full h-screen px-28 bg-content1">
      <div className="flex flex-col h-full space-y-16">
        <div className="flex flex-row space-x-11">
          <div className="flex flex-col space-y-2">
            <Typography className="text-chalk text-2xl font-medium tracking-[1.5px] leading-[28.5px] -mr-16 mt-[71px] z-10">
              {data.competition.name}
            </Typography>
            <Typography className="text-chalk text-3xl font-semibold tracking-[1.5px] leading-[28.5px] mt-[71px]">
              {t('competition.statistics')}
            </Typography>
          </div>
          <img
            className="h-[480px] w-[940px] object-cover"
            src={data.competition.statistic_image?.path}
            alt={data.competition.statistic_image?.caption || ''}
          />
          <div className="flex flex-col justify-end space-y-4 mt-8 items-start">
            <HtmlMarkup
              className={`text-chalk text-[26px] leading-[29.9px] tracking-[1.3px] max-w-[420px] ${
                isOverflowing ? 'max-h-[300px]' : 'max-h-[250px]'
              } overflow-y-auto pr-14`}
            >
              {data.competition.statistic_text}
            </HtmlMarkup>
            <Button
              className="bg-danger rounded-[32px] w-[140px] h-[72px]"
              onClick={openModal}
            >
              <Typography className="text-chalk text-2xl tracking-[1.4px] leading-[22px] uppercase">
                {t('competition.stats.view')} +
              </Typography>
            </Button>
          </div>
        </div>
        <div className="flex flex-row w-full px-24 pt-16 pb-24 bg-graphite/85 rounded-[46px] justify-between">
            <div className="flex flex-col space-y-12">
            {data.competition.has_external_statistics && data.extra_info ? (
              <GameData gameData={data.extra_info.statistics.games} />
            ) : (
              <GameData gameData={data.competition.statistics.games} />
            )}
            </div>
          <div className="flex items-end">
            <Stack
              upperClass="w-[5px] h-[137px]"
              lowerClass="w-[5px] h-[73px]"
            />
          </div>
          <div className="flex flex-col space-y-12">
            {data.competition.has_external_statistics && data.extra_info ? (
              <GoalData
                totalGame={
                  (data.extra_info.statistics.games?.Defeats) +
                  (data.extra_info.statistics.games?.Draws) +
                  (data.extra_info.statistics.games?.Victories)
                }
                goalData={data.extra_info.statistics.games}
              />
            ) : (
              <GoalData
                totalGame={
                  (data.competition.statistics.games?.Defeats || 0) +
                  (data.competition.statistics.games?.Draws || 0) +
                  (data.competition.statistics.games?.Victories || 0)
                }
                goalData={data.competition.statistics.games}
              />
            )}
          </div>
          <div className="flex items-end">
            <Stack
              upperClass="w-[5px] h-[137px]"
              lowerClass="w-[5px] h-[73px]"
            />
          </div>
          <div className="flex flex-col space-y-8">
            {data.competition.has_external_statistics && data.extra_info ? (
              <BestScore goalScorer={data.extra_info.statistics.goal_scorers} /> )
            : <BestScore goalScorer={data.competition.statistics.goal_scorers} /> }
          </div>
        </div>
      </div>
      <ScrollingDialog
        open={open}
        onClose={closeModal}
        curiosityText={data.competition.curiosity_text}
        curiosityImage={data.competition.curiosity_image}
        title={`${t('competition.stats.curiosities')} ${name}`}
      />
    </div>
  );
};
