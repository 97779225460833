import { ROUTES } from '@/config';
import { Button, Typography } from '@/core/ui';
import { cn } from '@/lib';
import { Link, useLocation } from 'react-router-dom';

export const NotFound = () => {
  const location = useLocation();
  const pathSegments = location.pathname.split('/').filter(Boolean);
  const modalityId = pathSegments[0];
  
return (
  <div
    className={cn(
      'flex h-screen w-full items-center justify-center',
      'bg-objects bg-apple bg-cover bg-center bg-no-repeat',
    )}
  >
    <div
      className={cn(
        'w-full lg:max-w-3xl max-w-xl rounded-md bg-chalk py-56 shadow-xl',
        'flex flex-col items-center justify-center space-y-12',
      )}
    >
      <img alt='Football' className='h-32 w-full' height='21' src='/logo.svg' width='100%' />
      <div className='text-center flex flex-col items-center space-y-6'>
        <Typography className='text-4xl text-content1'>You seem to be lost!</Typography>
        <Link to={`${ROUTES.HOME.MAIN}/${modalityId}/${ROUTES.COMPETITION.MAIN}`}>
          <Button className='text-2xl px-8 py-8 max-w-fit'>Go to Home</Button>
        </Link>
      </div>
    </div>
  </div>
)};
