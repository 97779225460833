import {
  CompetitionDetailLoading,
  CompetitionMembers,
  CompetitionStats,
  Galleria,
  Gallery,
  TrophyData,
} from '@/components/competitions';
import { ROUTES } from '@/config';
import { Icon } from '@/core/icon';
import {
  Button,
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
  CarouselScrollBar,
} from '@/core/ui';
import { API } from '@/store/api';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import { useSendCommandMutation } from '@/store/onecore/api';
import { useEffect } from 'react';

const CompetitionDetail = () => {
  const { competitionId, modalityId } = useParams();
  const { i18n } = useTranslation();

  const {
    error,
    isLoading,
    isFetching,
    data,
  } = API.competitionApi.useGetCompetitionByIdQuery(
    {
      competitionId: Number.parseInt(competitionId as string, 10),
      locale: i18n.language,
    },
    { skip: !competitionId || !i18n.language },
  );

  const [sendCommand] = useSendCommandMutation();

  const { data: configData } = API.oneCoreApi.useGetOneCoreConfigQuery();

  const handleSendZero = async () => {
    if (!configData?.data?.settings) {
      console.error('Configuration data is not available.');
      return;
    }

    if (!data?.competition?.shelly) {
      console.error('Shelly configuration is not available.');
      return;
    }

    try {
      const { IP_ADDRESS, TOKEN } = configData.data.settings;

      await sendCommand({
        app: 'hall_of_champions',
        address: IP_ADDRESS ?? '192.168.0.1',
        key: TOKEN,
        options: {
          command: 'setChannelValue',
          channel: data.competition.shelly.channel,
          value: 0,
        },
        devices: [data.competition.shelly.device_id],
      }).unwrap();

      console.log('Command sent successfully');
    } catch (error) {
      console.error('Failed to send command:', error);
    }
  };

  useEffect(() => {
    const handleUnload = async () => {
      await handleSendZero();
    };

    window.addEventListener('beforeunload', handleUnload);

    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, []);

  if (error) {
    return <p>Oh no, there was an error</p>;
  }

  if (isLoading || isFetching || !data) {
    return <CompetitionDetailLoading />;
  }

  if (!data.competition) {
    return <p>Competition data is not available</p>;
  }

  return (
    <div className='w-full h-full'>
      <Carousel opts={{ align: 'start' }}>
        <CarouselContent>
          <CarouselItem className='pl-0 -mr-1'>
            <TrophyData competition={data.competition} />
          </CarouselItem>
          <CarouselItem className='pl-0 -mr-1'>
            <CompetitionStats data={data} />
          </CarouselItem>
          <CarouselItem className='pl-0 -mr-1'>
            <CompetitionMembers
              title={`${data.competition.name}`}
              squad={data.competition.squad}
              technical_team={data.competition.technical_team}
            />
          </CarouselItem>
          <CarouselItem className='pl-0 basis-[41%]'>
            <Galleria president={data.competition.president_info} competitionName={data.competition.name} />
          </CarouselItem>
          <CarouselItem className='pl-0 -ml-0.5'>
            <Gallery
              gallery={data.competition.gallery}
              highlight={data.competition.highlight}
              competitionName={data.competition.name}
            />
          </CarouselItem>
        </CarouselContent>
        <CarouselNext className='!bg-transparent disabled:bg-transparent right-0 disabled:hidden'>
          <Icon name='CHEVRON_RIGHT' className='w-10 h-10' />
        </CarouselNext>
        <CarouselPrevious className='!bg-transparent disabled:bg-transparent left-0 disabled:hidden '>
          <Icon name='CHEVRON_LEFT' className='w-10 h-10' />
        </CarouselPrevious>

        <Link
          to={`${ROUTES.HOME.MAIN}/${modalityId}/${ROUTES.COMPETITION.MAIN}`}
          className={'absolute top-14 right-20'}
        >
          <Button className='border-none flex px-7 py-8 bg-danger rounded-full items-center space-x-2'
            onClick={handleSendZero}
            >
            <Icon className='h-5 w-5' name='CHEVRON_LEFT' />
            <Icon className='h-7 w-6' name='HOME' />
          </Button>
        </Link>

        <CarouselScrollBar />
      </Carousel>
    </div>
  );
};
export default CompetitionDetail;
