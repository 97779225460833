import { Icon } from '@/core/icon';
import { Button, Typography } from '@/core/ui';
import { cn } from '@/lib/utils';
import { useAppDispatch } from '@/store';
import { setMember } from '@/store/competition';
import type { EMember, IPicture } from '@/types';

interface IProps extends React.ComponentProps<'div'> {
  name: string;
  position: string;
  link: boolean;
  image: IPicture;
  playerId: number;
  type: EMember;
}

export const PlayerCard = ({ image, playerId, type, name, position, link, className }: IProps) => {
  const dispatch = useAppDispatch();

  const onMemberClick = () => {
    dispatch(setMember({ id: playerId, type }));
  };

  return (
    <div
      className={cn(
        'flex flex-row space-x-[14px] items-center relative overflow-hidden',
        'w-[290px] h-[108px] rounded-[17px] p-[19px]',
        'bg-olive',
        className,
      )}
    >
      {image.path ? (
        <img
          src={image.path}
          alt={image.caption || ''}
          className='bg-chalk w-[4.5rem] rounded-full h-[4.5rem] object-cover overflow-hidden'
        />
      ) : (
        <img
          src='/default.png'
          alt={image.caption || ''}
          className='bg-chalk w-[4.5rem] rounded-full h-[4.5rem] object-cover overflow-hidden'
        />
      )}

      <div className='flex flex-col h-full justify-between'>
        <Typography
          className={`text-chalk ${link ? 'w-[100px]' : 'w-full'} text-[19.5px] leading-[19.5px] uppercase font-semibold tracking-[0.97px]`}
        >
          {name}
        </Typography>
        <Typography
          className={`text-chalk ${link ? 'w-[100px]' : 'w-[98px]'} text-[17px] leading-[17px] tracking-[0.86px]`}
        >
          {position}
        </Typography>
      </div>
      {link && (
        <Button
          onClick={onMemberClick}
          className='absolute right-0 w-[77px] h-full rounded-l-none rounded-r-[18px] bg-danger flex justify-center items-center'
        >
          <Icon name='PLUS_CIRCLE' className='w-16 h-16 scale-[1.7]' />
        </Button>
      )}
    </div>
  );
};
